import {
  ACTION_ACCEPT_REAL_GIFT__REQUEST,
  ACTION_ACCEPT_REAL_GIFT__SUCCESS,
  ACTION_ACCEPT_REAL_GIFT__FAIL,
  ACTION_REJECT_REAL_GIFT__REQUEST,
  ACTION_REJECT_REAL_GIFT__SUCCESS,
  ACTION_REJECT_REAL_GIFT__FAIL,
} from '../../actionTypes';

import { REAL_GIFTS_GRID_NAME } from '../../constants/realGift';

import { actionLoadRealGiftsRequest } from '../../actions/realGiftsGrid';

import { getGridSaga } from 'react-redux-app/lib/modules/grid/sagas';


export default getGridSaga(
  REAL_GIFTS_GRID_NAME,
  actionLoadRealGiftsRequest,
  [ACTION_ACCEPT_REAL_GIFT__REQUEST, ACTION_REJECT_REAL_GIFT__REQUEST],
  [ACTION_ACCEPT_REAL_GIFT__FAIL, ACTION_REJECT_REAL_GIFT__FAIL],
  [ACTION_ACCEPT_REAL_GIFT__SUCCESS, ACTION_REJECT_REAL_GIFT__SUCCESS]
);

import {
  ACTION_LOAD_REAL_GIFT_DELIVERY_ADDRESS__REQUEST,
  ACTION_LOAD_REAL_GIFT_DELIVERY_ADDRESS__SUCCESS,
  ACTION_LOAD_REAL_GIFT_DELIVERY_ADDRESS__FAIL,
} from '../actionTypes';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';


const actionLoadRealGiftDeliveryAddressSuccess = deliveryAddress => ({
  type: ACTION_LOAD_REAL_GIFT_DELIVERY_ADDRESS__SUCCESS,
  payload: { deliveryAddress },
});

const actionLoadRealGiftDeliveryAddressFail = error => ({
  type: ACTION_LOAD_REAL_GIFT_DELIVERY_ADDRESS__FAIL,
  payload: { error },
});

export const actionLoadRealGiftDeliveryAddressRequest = () => (
  (dispatch, getState, { apiClientHelper }) => {
    dispatch({
      type: ACTION_LOAD_REAL_GIFT_DELIVERY_ADDRESS__REQUEST,
    });

    return apiClientHelper.get(
      'account/delivery_address'
    ).then(
      deliveryAddress => {
        dispatch(actionLoadRealGiftDeliveryAddressSuccess(deliveryAddress));
      },
      error => {
        dispatch(actionLoadRealGiftDeliveryAddressFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);

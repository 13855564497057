import { combineReducers } from 'redux';

import acceptRealGiftError from './acceptRealGiftError';
import loadGiftsError from './loadGiftsError';
import loadRealGiftsDeliveryAddressError from './loadRealGiftsDeliveryAddressError';
import rejectRealGiftError from './rejectRealGiftError';
import sendGiftError from './sendGiftError';


export default combineReducers({
  acceptRealGiftError,
  loadGiftsError,
  loadRealGiftsDeliveryAddressError,
  rejectRealGiftError,
  sendGiftError,
});

import { useLocation, useNavigate } from 'react-router-dom';

import { getPublicRoomOverlayLocation } from '../utils/publicRoom';


export default () => {
  const location = useLocation();
  const navigate = useNavigate();

  return () => navigate(getPublicRoomOverlayLocation(location));
};

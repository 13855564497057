import _get from 'lodash/get';


const errorsSelector = state => _get(state, 'gift.errors');
export const getAcceptRealGiftError = state => (
  _get(errorsSelector(state), 'acceptRealGiftError')
);
export const getLoadGiftsError = state => (
  _get(errorsSelector(state), 'loadGiftsError')
);
export const getLoadRealGiftsDeliveryAddressError = state => (
  _get(errorsSelector(state), 'loadRealGiftsDeliveryAddressError')
);
export const getRejectRealGiftError = state => (
  _get(errorsSelector(state), 'rejectRealGiftError')
);
export const getSendGiftError = state => (
  _get(errorsSelector(state), 'sendGiftError')
);

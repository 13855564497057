import { getGridFilter } from '../utils/realGift';

import { ACTION_ADD_REAL_GIFT_OFFERS } from '../actionTypes';

import { REAL_GIFTS_GRID_NAME } from '../constants/realGift';

import { getActionLoadGridDataRequest } from '../../core/grid/actions';


export const actionAddRealGiftOffers = realGiftOffers => ({
  type: ACTION_ADD_REAL_GIFT_OFFERS,
  payload: { realGiftOffers },
});

export const actionLoadRealGiftsRequest = getActionLoadGridDataRequest(
  REAL_GIFTS_GRID_NAME, getGridFilter, 'account/real_gift/offer/list', actionAddRealGiftOffers
);

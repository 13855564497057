import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { useCallCancellablePromiseAndHandleErrorOrExecute } from '../../../../core/api/hooks';
import { useLoadCountries } from '../../../../initSettings/hooks';

import { l } from 'react-redux-app/lib/modules/i18n/utils';

import { getLoadRealGiftsDeliveryAddressError } from '../../../selectors/errors';

import { actionLoadRealGiftDeliveryAddressRequest } from '../../../actions/loadRealGiftDeliveryAddress';
import { actionShowRealGiftDialog } from '../../../actions/dialogs/realGiftDialog';

import WhiteButton from '../../../../core/components/buttons/WhiteButton';


const propTypes = {
  id: PropTypes.number.isRequired,
  nick: PropTypes.string,
};

const ViewRealGiftButton = ({ id, nick }) => {
  const dispatch = useDispatch();

  const [isBeingLoaded, setIsBeingLoaded] = useState(false);

  const loadCountries = useLoadCountries();

  const callActionLoadRealGiftDeliveryAddressRequest = (
    useCallCancellablePromiseAndHandleErrorOrExecute(getLoadRealGiftsDeliveryAddressError)
  );
  const showRealGiftDialog = () => {
    setIsBeingLoaded(true);

    callActionLoadRealGiftDeliveryAddressRequest(
      dispatch(actionLoadRealGiftDeliveryAddressRequest()),
      'header.real-gift-delivery-address.load',
      () => {
        loadCountries(() => {
          dispatch(actionShowRealGiftDialog(id, nick));
          setIsBeingLoaded(false);
        });
      }
    );
  };

  return (
    <WhiteButton type="button" onClick={showRealGiftDialog} isSpinnerEnabled={isBeingLoaded}>
      {l('action.view')}
    </WhiteButton>
  );
};

ViewRealGiftButton.propTypes = propTypes;

export default ViewRealGiftButton;

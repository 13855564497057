import { MODAL_DIALOG__REAL_GIFT } from '../../constants/dialogs';

import { actionShowModal, actionHideModal } from 'react-redux-app/lib/modules/modal/actions';


export const actionShowRealGiftDialog = (id, nick) => dispatch => (
  dispatch(actionShowModal(MODAL_DIALOG__REAL_GIFT, { id, nick }))
);

export const actionHideRealGiftDialog = () => dispatch => (
  dispatch(actionHideModal(MODAL_DIALOG__REAL_GIFT))
);

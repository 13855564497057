import { useDispatch } from 'react-redux';

import { useCallCancellablePromiseAndHandleErrorOrExecute } from '../../core/api/hooks';

import { getLoadPartialInitSettingsError } from '../selectors/errors';

import { actionLoadCountriesRequest } from '../actions/loadPartialInitSettings';


export default () => {
  const dispatch = useDispatch();

  const callActionLoadCountriesRequest = (
    useCallCancellablePromiseAndHandleErrorOrExecute(getLoadPartialInitSettingsError)
  );

  return callback => {
    callActionLoadCountriesRequest(
      dispatch(actionLoadCountriesRequest()),
      'header.countries.load',
      callback
    );
  };
};

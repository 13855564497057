import React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useShortSelector } from 'react-redux-app/lib/modules/core/hooks';
import useCurrentLangForUrl from '../../../core/i18n/hooks/useCurrentLangForUrl';

import { getEventUrl, getSugarBabyUrl } from '../../utils/url';

import { getEventBannerUrl } from '../../../initSettings/selectors';

import { actionGetCredits } from '../../../deposit/actions/getCredits';

import LangLink from '../../../core/i18n/components/LangLink';

import styles from './styles/index.css.json';


export default () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const bannerUrl = useShortSelector(getEventBannerUrl);
  const langForUrl = useCurrentLangForUrl();

  return bannerUrl && pathname !== getEventUrl(langForUrl) && (
    <div
      onClick={() => { dispatch(actionGetCredits(null, false, false, true)); }}
      className={styles.banner}
    >
      {/* <LangLink to={getSugarBabyUrl()}> */}
      <iframe
        title="Big Black Friday"
        frameBorder="0"
        marginHeight="0"
        marginWidth="0"
        scrolling="no"
        src={bannerUrl}
        width="100%"
        height="68"
        className={styles.iFrame}
      />
    </div>
  );
};

import { PUBLIC_ROOM_HASH } from '../constants';


export const hasPublicRoomHash = hash => hash.startsWith(PUBLIC_ROOM_HASH);

export const getPublicRoomOverlayLocation = location => {
  const modifiedLocation = { ...location };
  modifiedLocation.hash = PUBLIC_ROOM_HASH;
  return modifiedLocation;
};

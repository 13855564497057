import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import loadable from '@loadable/component';

import { useShortSelector } from 'react-redux-app/lib/modules/core/hooks';
import { useCallCancellablePromiseAndHandleErrorOrExecute } from '../../../core/api/hooks';
import useCurrentLangForUrl from '../../../core/i18n/hooks/useCurrentLangForUrl';

import { hasPublicRoomHash } from '../../utils/publicRoom';
import { getPublicRoomUrl } from '../../utils/url';

import { getChatSettings } from '../../../initSettings/selectors';
import { getLoadPartialInitSettingsError } from '../../../initSettings/selectors/errors';

import { actionVisitPageRequest } from '../../../auth/actions/visitPage';
import { actionLoadChatSettingsRequest } from '../../../initSettings/actions/loadPartialInitSettings';

import Overlay from '../../../core/components/Overlay';

import styles from './styles/index.css.json';


const Chat = loadable(() => import('../../../model/components/Chat'));

export default () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const chatSettings = useShortSelector(getChatSettings);
  const langForUrl = useCurrentLangForUrl();

  const isPublicRoomVisible = useMemo(() => hasPublicRoomHash(location.hash), [location.hash]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const closeUrl = useMemo(() => ({ ...location, hash: '' }), [isPublicRoomVisible]);

  const callActionLoadChatSettingsRequest = (
    useCallCancellablePromiseAndHandleErrorOrExecute(getLoadPartialInitSettingsError)
  );

  useEffect(() => {
    if (!isPublicRoomVisible) {
      return;
    }

    dispatch(actionVisitPageRequest({
      pathname: getPublicRoomUrl(langForUrl),
      search: '',
      hash: '',
    }));

    callActionLoadChatSettingsRequest(
      dispatch(actionLoadChatSettingsRequest()),
      'header.chat-settings.load'
    );
  }, [isPublicRoomVisible]); // eslint-disable-line react-hooks/exhaustive-deps

  return isPublicRoomVisible && chatSettings && (
    <Overlay>
      <Chat isPublicRoom closeUrl={closeUrl} className={styles.chat} />
    </Overlay>
  );
};

export const ACTION_ADD_REAL_GIFT_OFFERS = 'ACTION_ADD_REAL_GIFT_OFFERS';

export const ACTION_LOAD_GIFTS__REQUEST = 'ACTIONO_LOAD_GIFTS__REQUEST';
export const ACTION_LOAD_GIFTS__SUCCESS = 'ACTIONO_LOAD_GIFTS__SUCCESS';
export const ACTION_LOAD_GIFTS__FAIL = 'ACTIONO_LOAD_GIFTS__FAIL';

export const ACTION_SEND_GIFT__REQUEST = 'ACTION_SEND_GIFT__REQUEST';
export const ACTION_SEND_GIFT__SUCCESS = 'ACTION_SEND_GIFT__SUCCESS';
export const ACTION_SEND_GIFT__FAIL = 'ACTION_SEND_GIFT__FAIL';

export const ACTION_SELECT_GIFT = 'ACTION_SELECT_GIFT';

export const ACTION_SET_IS_GIFT_SENDING_CONFIRMED = 'ACTION_SET_IS_GIFT_SENDING_CONFIRMED';

export const ACTION_LOAD_REAL_GIFT_DELIVERY_ADDRESS__REQUEST = 'ACTION_LOAD_REAL_GIFT_DELIVERY_ADDRESS__REQUEST';
export const ACTION_LOAD_REAL_GIFT_DELIVERY_ADDRESS__SUCCESS = 'ACTION_LOAD_REAL_GIFT_DELIVERY_ADDRESS__SUCCESS';
export const ACTION_LOAD_REAL_GIFT_DELIVERY_ADDRESS__FAIL = 'ACTION_LOAD_REAL_GIFT_DELIVERY_ADDRESS__FAIL';

export const ACTION_ACCEPT_REAL_GIFT__REQUEST = 'ACTION_ACCEPT_REAL_GIFT__REQUEST';
export const ACTION_ACCEPT_REAL_GIFT__SUCCESS = 'ACTION_ACCEPT_REAL_GIFT__SUCCESS';
export const ACTION_ACCEPT_REAL_GIFT__FAIL = 'ACTION_ACCEPT_REAL_GIFT__FAIL';

export const ACTION_REJECT_REAL_GIFT__REQUEST = 'ACTION_REJECT_REAL_GIFT__REQUEST';
export const ACTION_REJECT_REAL_GIFT__SUCCESS = 'ACTION_REJECT_REAL_GIFT__SUCCESS';
export const ACTION_REJECT_REAL_GIFT__FAIL = 'ACTION_REJECT_REAL_GIFT__FAIL';

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _omit from 'lodash/omit';

import useNavigateToProfileOverlay from '../../hooks/useNavigateToProfileOverlay';

import { getProfileUrl } from '../../../app/utils/url';

import BlueCheckContainer from '../../../core/components/blueCheck/BlueCheckContainer';
import LangLink from '../../../core/i18n/components/LangLink';
import TextLangLink from '../../../core/i18n/components/TextLangLink';

import styles from './styles/index.css.json';


const propTypes = {
  id: PropTypes.number,
  nick: PropTypes.string.isRequired,
  isVisible: PropTypes.bool,
  isText: PropTypes.bool,
  isEllipsisText: PropTypes.bool,
  isBlueChecked: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
  valueClassName: PropTypes.string,
};

const ProfileLink = ({
  id = null,
  nick,
  isVisible = true,
  isText = false,
  isEllipsisText = false,
  isBlueChecked = false,
  onClick = null,
  children = null,
  className = null,
  valueClassName = null,
  ...rest
}) => {
  const navigateToProfileOverlay = useNavigateToProfileOverlay();

  const isT = isText || isEllipsisText;
  const classNames = classnames(valueClassName, {
    [styles.ellipsisText]: isEllipsisText,
    [className]: !isBlueChecked,
  });
  const output = children || nick;

  const getWrappedContent = content => (!isBlueChecked ? content : (
    <BlueCheckContainer
      content={content}
      blueCheckProps={{ isBlueChecked }}
      className={className}
    />
  ));

  if (!isVisible) {
    if (isT) {
      return getWrappedContent(<span className={classNames}>{output}</span>);
    }

    return className ? <div className={className}>{children}</div> : children;
  }

  const LinkComponent = isT ? TextLangLink : LangLink;
  return getWrappedContent(
    <LinkComponent
      {...rest}
      to={getProfileUrl(id)}
      onClick={e => {
        e.preventDefault();
        navigateToProfileOverlay(id);
        onClick?.(e);
      }}
      className={classNames}
    >
      {output}
    </LinkComponent>
  );
};

ProfileLink.propTypes = propTypes;

export default ProfileLink;

export const REQUESTS_TAB__APPOINTMENTS = 'appointments';
export const REQUESTS_TAB__INTERVIEWS = 'interviews';
export const REQUESTS_TAB__REAL_GIFTS = 'realGifts';
export const REQUESTS_TAB__UNDERWEAR = 'underwear';

export const requestsTabs = [
  REQUESTS_TAB__REAL_GIFTS,
  REQUESTS_TAB__APPOINTMENTS,
  REQUESTS_TAB__INTERVIEWS,
  REQUESTS_TAB__UNDERWEAR,
];

export const RIVSTREAMER_TAB__RIV_CREDENTIALS = 'rivCredentials';
export const RIVSTREAMER_TAB__STREAM_PARAMETERS = 'streamParameters';

export const rivstreamerTabs = [
  RIVSTREAMER_TAB__RIV_CREDENTIALS,
  RIVSTREAMER_TAB__STREAM_PARAMETERS,
];

import { useDispatch } from 'react-redux';

import { useCallCancellablePromiseAndHandleErrorOrExecute } from '../../core/api/hooks';
import { useIsAuthenticated } from '../../auth/hooks';

import { getLoadGiftsError } from '../selectors/errors';

import { actionShowLoginDialog } from '../../auth/actions/dialogs/loginDialog';
import { actionLoadGiftsRequest } from '../actions/loadGifts';
import { actionShowSendGiftDialog } from '../actions/dialogs/sendGiftDialog';


export default (isReal = false) => {
  const dispatch = useDispatch();

  const isAuthenticated = useIsAuthenticated();

  const callActionLoadGiftsRequest = (
    useCallCancellablePromiseAndHandleErrorOrExecute(getLoadGiftsError)
  );
  return (id, nick) => {
    if (!isAuthenticated) {
      dispatch(actionShowLoginDialog());
      return;
    }

    callActionLoadGiftsRequest(
      dispatch(actionLoadGiftsRequest(isReal)),
      isReal ? 'header.real-gifts.load' : 'header.gifts.load',
      () => { dispatch(actionShowSendGiftDialog(id, nick, isReal)); }
    );
  };
};

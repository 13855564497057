import { MODAL_DIALOG__SEND_GIFT } from '../../constants/dialogs';

import { actionShowModal, actionHideModal } from 'react-redux-app/lib/modules/modal/actions';


export const actionShowSendGiftDialog = (modelId, modelNick, isReal) => dispatch => (
  dispatch(actionShowModal(MODAL_DIALOG__SEND_GIFT, { modelId, modelNick, isReal }))
);

export const actionHideSendGiftDialog = () => dispatch => (
  dispatch(actionHideModal(MODAL_DIALOG__SEND_GIFT))
);

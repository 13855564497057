import React from 'react';

import Loading from '../components/Loading';


export const ADULT_WARNING_COOKIE = 'adult-warning-accepted';
export const COOKIE_WARNING_EXPIRES = 0;

export const PUBLIC_ROOM_HASH = '#!public-room';

export const options = { fallback: <Loading /> };

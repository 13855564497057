import { getIsAuthenticated } from '../../auth/selectors';

import { actionShowLoginDialog } from '../../auth/actions/dialogs/loginDialog';
import { actionClearGateResult } from './buyCreditsLocally';
import { actionShowGetCreditsDialog } from './dialogs/getCreditsDialog';


export const actionGetCredits = (error, welcomeBonus, cryptoBonus, ignoreLastMethod) => (
  (dispatch, getState) => {
    if (getIsAuthenticated(getState())) {
      dispatch(actionClearGateResult());
      dispatch(actionShowGetCreditsDialog(error, welcomeBonus, cryptoBonus, ignoreLastMethod));
    } else {
      dispatch(actionShowLoginDialog());
    }
  }
);
